/*
 * @Author: x 7b xqb081010@qq.com
 * @Date: 2022-09-22 11:49:18
 * @LastEditors: x 7b xqb081010@qq.com
 * @LastEditTime: 2022-09-23 17:26:12
 * @FilePath: \examhnag\src\router\index.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    redirect: "/login",
  },
  {
    path: "/login",
    name: "登录",
    component: () => import("@/views/login/index.vue"),
  },
  // {
  //   path: "/test",
  //   name: "测试",
  //   component: () => import("@/views/signtrue"),
  // },
  {
    path: "/confirm",
    name: "que",
    component: () => import("@/views/confirm/index.vue"),
  },
  {
    path: "/do/:id",
    name: "答题",
    component: () => import("@/views/do/index.vue"),
  },
  {
    path: "/exhibition/:studentExamId",
    name: "考试成绩展示",
    component: () => import("@/views/exhibition/index.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
