import { createStore } from "vuex";

export default createStore({
  state: {
    user: {},
    lk: 3,
  },
  mutations: {
    updateUser(state, user) {
      state.user = user
    },
    increment (state) {
      state.lk--
    }
  },
  actions: {
    increment (context) {
      context.commit('increment')
    }
  },
  modules: {},
});
